<template>
  <button :class="buttonClasses">
    <div
      class="flex items-center justify-center"
      :class="iconLocation == 'right' ? 'flex-row-reverse' : ''"
    >
      <UIcon
        v-if="icon || loading"
        :name="loading || !icon ? 'i-line-md-loading-twotone-loop' : icon"
        :class="(iconOnly || loading ? '' : iconLocation == 'left' ? 'mr-1 -ml-0.5 ' : 'ml-1 -mr-0.5') + iconClasses"
        :spin="loading"
        :flip="iconFlip"
        v-bind="iconAttributes"
      />
      <span
        v-show="!loading"
        :class="`font-medium text-${size} ${showLabelOnSm ? '' : 'hidden md:block'}`"
      >
        {{ label }}
      </span>
    </div>
  </button>
</template>

<script lang="ts" setup>
export type HubButtonType = 'default' | 'outlined' | 'outlined-transparent' | 'ghost'
export type HubButtonColours = 'default' | 'danger' | 'success' | 'warning'

interface Props {
  label?: string
  icon?: string
  iconClasses?: string
  iconLocation?: 'left' | 'right'
  iconFlip?: undefined | 'horizontal' | 'vertical' | 'both'
  iconAttributes?: object
  iconOnly?: boolean
  rounded?: boolean // only applies to icon only buttons
  loading?: boolean
  type?: HubButtonType
  color?: HubButtonColours
  btnStyle?: string
  size?: 'sm' | 'lg' | 'xs'
  showLabelOnSm?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  label: undefined,
  icon: undefined,
  iconClasses: undefined,
  iconLocation: 'left',
  iconOnly: false,
  iconFlip: undefined,
  iconAttributes: undefined,
  rounded: true,
  loading: false,
  type: 'default',
  color: 'default',
  btnStyle: undefined,
  size: 'sm',
  showLabelOnSm: true
})

const buttonClasses = computed(() => {
  let classes: string = buttonStyle.value

  if (props.iconOnly && !props.iconClasses) {
    if (props.rounded) {
      return classes + '  rounded-full p-2 text-white'
    }

    classes += ' p-2.5 text-white'
  }

  return classes
})

const buttonStyle: Ref<string> = computed(() => {
  if (props.btnStyle) {
    return props.btnStyle
  }

  // colours - defaults
  let btnColor: string = 'trublue'
  let hoverColor: string = 'trublue-600'
  let btnColorNeedsPrefix: boolean = false

  // colours - options
  switch (props.color) {
    case 'danger': {
      btnColor = 'product'
      hoverColor = 'red-500'
      break
    }
    case 'warning': {
      btnColor = 'value'
      hoverColor = 'amber-500'
      break
    }
    case 'success': {
      btnColorNeedsPrefix = true
      btnColor = 'green'
      hoverColor = 'emerald-500'
      break
    }
  }

  // apply colours to types

  // purgecss: bg-trublue hover:bg-trublue-600
  // purgecss: bg-product hover:bg-red-500
  // purgecss: bg-value hover:bg-amber-500
  // purgecss: bg-green-500 hover:bg-emerald-500
  let style = `bg-${btnColor}${
    btnColorNeedsPrefix ? '-500' : ''
  } hover:bg-${hoverColor} text-white disabled:opacity-50 disabled:pointer-events-none`

  switch (props.type) {
    case 'outlined': {
      // purgecss: bg-white border-2 rounded-lg border-trublue text-trublue hover:bg-trublue hover:text-white
      // purgecss: border-product text-product hover:bg-red
      // purgecss: border-value text-value hover:bg-amber
      // purgecss: border-green text-green hover:bg-emerald
      style = `bg-white border-2 rounded-lg border-${btnColor} text-${btnColor} hover:bg-${btnColor} hover:text-white`
      break
    }
    case 'outlined-transparent': {
      style = `bg-transparent border-2 rounded-lg border-${btnColor} text-${btnColor}
                hover:bg-${btnColor} hover:text-white font-semibold`
      break
    }
    case 'ghost': {
      // purgecss: bg-trublue hover:bg-trublue-600/10
      // purgecss: bg-product hover:bg-red-500/10
      // purgecss: bg-value hover:bg-amber-500/10
      // purgecss: bg-green-500 hover:bg-emerald-500/10
      // purgecss: bg-product hover:bg-product/10
      style = `bg-transparent text-${btnColor} hover:bg-${hoverColor}/10 font-semibold`
      break
    }
  }

  style += ' rounded-lg'

  switch (props.size) {
    case 'lg':
      style += ' px-6 py-2'
      break
    case 'xs':
      if (props.type !== 'default') {
        style += ' px-4 py-1.5'
      } else {
        style += ' px-4 py-2'
      }
      break
    default:
      style += ' px-4 py-2'
  }

  return style
})
</script>
