<template>
  <div>
    <NuxtLayout class="text-purple">
      <NuxtPage />
    </NuxtLayout>

    <UNotifications class="z-[100]" />
    <ConfirmDialog
      id="prime-generic-confirmation"
      :pt="{
        root: ' bg-white p-4 m-4 rounded-lg',
        header: 'font-semibold pb-4',
        message: 'text-sm',
        footer: 'flex justify-end pt-4 text-sm',
        // @ts-ignore I don't know how to make this type safe but it's cut/pasted from the docs
        mask: ({ props }) => ({
          class: ['transition duration-200', { 'bg-black/40': props.modal }],
        }),
      }"
    />
    <HubBackToTopButton />
  </div>
</template>

<script setup lang="ts">
import 'primevue/resources/themes/lara-light-blue/theme.css'
import ConfirmDialog from 'primevue/confirmdialog'

useHead({
  titleTemplate: titleChunk => {
    return titleChunk ? `${titleChunk} - TruHub` : 'TruHub'
  }
})
</script>
